import { computed, ref } from 'vue'
import { StreamsService } from '@obr-core/services/api'
import { uiStoreService, userStoreService } from '@obr-core/services/store'
import { ModalService } from '@obr-core/services/ModalService'
import { StreamType } from '@obr-core/config/media'
import { i18n } from '@obr-core/i18n/i18n'
import {
    filterStreamsChannels,
    getStreamInformation,
} from '@obr-core/helpers/streams.helpers'
import { isTimestampInPast } from '@obr-core/helpers/date.helpers'
import { getDisplayValueForNextRaceTimeLeft } from '@obr-core/helpers/display-value.helpers'
import { openStreamPopup } from '@obr-ui/helpers/streams.helpers'
import { setItem, getItem } from '@obr-core/lib/storage.manager'
import { STORAGE_KEY_MEDIA } from '@obr-core/config/storage'
import {
    updateChannelRacesByIdChannel,
    createChannelsWithRaces,
    createStreamsListChannels,
} from './helpers'
import { isTouchScreen } from '@obr-core/helpers/app.helpers'

const isVisible = ref<boolean>(false)
const isModalVisible = ref<boolean>(false)
const isPlayerSticky = ref<boolean>(false)
const isPopupVisible = ref<boolean>(false)
const isStandaloneVisible = ref<boolean>(false)
const isPiPOpen = ref<boolean>(false)
const mediaPlayingIdRace = ref<string | null>(null)
const mediaSources = ref<OBR.UI.Components.Streams.Source[]>([])
const mediaType = ref<'video' | 'audio' | 'iframe' | 'script'>('video')
const scriptInfo = ref<any>({})
// currently playing "request ID", we need to keep this data to be able to close stream afterwards
const mediaRequestId = ref<number | null>(null)

// Next streams start time left in hour when there is no streams available
const nextStreamsStartTimeLeft = ref<string | null>(null)

// channel list related
const isFetchingChannels = ref<boolean>(false)
const idChannelFetching = ref<string | null>(null)
const idChannelPlaying = ref<string | null>(null)
const channels = ref<OBR.UI.Components.Streams.ChannelWithRaces[]>([])
/**
 * Default channel to play
 *
 * i.e. default channel is a first available channel in the list
 * e.g. play default channel after clicking on play button
 */
const idChannelDefault = computed<string | null>(
    () =>
        channels.value.find(({ start_time }) => !isTimestampInPast(start_time))
            ?.id_channel || null
)
const isPlayButtonVisible = computed(() => {
    if (idChannelPlaying.value || isFetchingChannels.value) {
        return false
    }

    return !!idChannelDefault.value
})

/**
 * Close streams player
 *
 * e.g. clicking on close cross in streams player
 */
function onClose() {
    isVisible.value = false
    channels.value = []

    stopPlayingMedia()
}

/**
 * Open streams player
 *
 * e.g. clicking on stream action from "Event Card" or "Race Card"
 */
async function onOpen(preventOpeningModal?: boolean) {
    //if PiP is open & is visible, close PiP mode
    if (isPiPOpen.value && isVisible.value) {
        onLeavePictureInPicture()

        return
    }

    // in case when is open, close it
    if (isVisible.value) {
        onClose()

        return
    }

    // Close standalone if open before opening streams
    onCloseStandalone()

    isVisible.value = true

    // prevent modal from opening
    // e.g. in case we click on stream action we don't want to show channel list on mobile
    if (!preventOpeningModal) {
        onOpenModal()
    }

    // Start fetching channels
    await fetchChannels()
}

/**
 * Close modal with channels list
 *
 * i.e. when device screen is small or mobile devices
 */
function onCloseModal() {
    isModalVisible.value = false
}

/**
 * Open modal with channels
 *
 * i.e. when device screen is small or mobile devices
 */
function onOpenModal() {
    if (uiStoreService.isOnlyMobile()) {
        isModalVisible.value = true
    }
}

function onEnterPictureInPicture() {
    isPiPOpen.value = true
}

function onLeavePictureInPicture() {
    isPiPOpen.value = false
}

function handleStickyPlayer() {
    isPlayerSticky.value = !isPlayerSticky.value
}

function handlePlayerPopup() {
    const media = getItem(STORAGE_KEY_MEDIA) as OBR.Streams.Media

    if (isVisible.value) onClose()
    if (isStandaloneVisible.value) onCloseStandalone()

    openStreamPopup({
        streamType: media.stream_type,
        idChannel: idChannelPlaying.value || media.id_channel,
        idRace: mediaPlayingIdRace.value || media.id_race,
        provider: media.provider,
    })

    isPopupVisible.value = true
}

/**
 * Close media player
 *
 * e.g. clicking on close cross in media player
 */
function onCloseStandalone() {
    isStandaloneVisible.value = false
    mediaPlayingIdRace.value = null
    mediaSources.value = []
    mediaType.value = 'video'
}

/**
 * Open media player
 *
 * e.g. clicking on stream action from "Event Card" or "Race Card"
 */
async function onOpenStandalone(media: OBR.Streams.Media, isPopup = false) {
    //if PiP is open & is visible, close PiP mode
    if (isPiPOpen.value && isStandaloneVisible.value) {
        onLeavePictureInPicture()

        return
    }

    if (!canUserPlayMedia()) return

    // Close streams if open before opening media
    onClose()
    onCloseStandalone()

    // set media on local storage to get data on popup
    if (!isPopup) setItem(STORAGE_KEY_MEDIA, media)

    isStandaloneVisible.value = true
    mediaPlayingIdRace.value = media.id_race

    startPlayingMedia(media)
}

/**
 * Fetch all channels and filter them
 *
 * e.g. channels are shown in "StreamsList" component
 */
async function fetchChannels() {
    isFetchingChannels.value = true

    const response = await StreamsService.getInstance().getAllStreams()

    // filter channels and add races array to each channel
    channels.value = createChannelsWithRaces(filterStreamsChannels(response))

    isFetchingChannels.value = false

    // check if any channel is available
    checkChannelsAvailability()
}

/**
 * Fetch all races for specific channel
 *
 * e.g. races are shown in "StreamsList" component under specific channel
 */
async function fetchChannelRaces(idChannel: string): Promise<void> {
    idChannelFetching.value = idChannel

    const races = await StreamsService.getInstance().getStreamRaces(idChannel)

    // assign races to channel
    channels.value = updateChannelRacesByIdChannel(
        idChannel,
        channels.value,
        races
    )

    idChannelFetching.value = null
}

/**
 * Start playing channel and fetch all channel races
 *
 * e.g. used when clicking on channel in channel list
 */
async function startPlayingChannel(
    media: OBR.Streams.Media | OBR.UI.Components.Streams.ChannelWithRaces,
    isPopup = false
) {
    if (!canUserPlayMedia()) return

    const idChannel = media.id_channel

    // If channel is already open return/skip the rest
    if (idChannelPlaying.value === idChannel) {
        return
    }

    // Close previous channel before opening new one
    stopPlayingMedia()

    // Closing modal to be able to see stream playing on mobile
    onCloseModal()

    // set media on local storage to get data on popup
    if (!isPopup) setItem(STORAGE_KEY_MEDIA, media)
    // if is popup, set is visible to true
    else isVisible.value = true

    idChannelPlaying.value = idChannel

    // Fetch all races for current channel
    fetchChannelRaces(idChannel)

    // Start playing media
    startPlayingMedia(media)
}

/**
 * Check if channels are available
 *
 * e.g. if not show a message
 */
function checkChannelsAvailability() {
    // No available channels for current users
    if (channels.value.length === 0) {
        const modalService = ModalService.getInstance()

        // close streams and show confirmation modal
        onClose()

        modalService.confirmationModal({
            title: i18n.global.t('generic.msg_no_streams'),
            icon: 'broken-shoe',
            buttonText: i18n.global.t('generic.label_close'),
        })
    }

    // If channels are available but none of them is active
    if (channels.value.length > 0 && !idChannelDefault.value) {
        const nextStreamStartTime = channels.value[0].start_time

        // calculate time left in hours or minutes
        nextStreamsStartTimeLeft.value =
            getDisplayValueForNextRaceTimeLeft(nextStreamStartTime)
    }
}

/**
 * User has to be logged in to watch media
 */
function canUserPlayMedia() {
    if (!userStoreService.isLoggedIn()) {
        userStoreService.authRequired()

        return false
    }

    return true
}

/**
 * Play default channel
 *
 * e.g. click on play button
 */
function startPlayingDefaultChannel() {
    if (idChannelDefault.value) {
        const channel = channels.value.find(
            ({ id_channel }) => idChannelDefault.value === id_channel
        )

        if (channel) {
            startPlayingChannel(channel)
        }
    }
}

/**
 * Stop playing channel
 *
 * e.g. after closing streams or choosing to play another channel we have to stop current one
 */
function stopPlayingMedia() {
    if (mediaRequestId.value) {
        StreamsService.getInstance().closeStreams(mediaRequestId.value)
    }

    idChannelPlaying.value = null
    isStandaloneVisible.value = false
    mediaSources.value = []
    mediaRequestId.value = null
    mediaType.value = 'video'
}

/**
 * Play media in video player
 */
async function startPlayingMedia(
    media: OBR.Streams.Media | OBR.UI.Components.Streams.ChannelWithRaces
) {
    const stream: OBR.Streams.StreamRequired = {
        idChannel: media.id_channel,
        idRace: media.id_race,
        archiveLink: '',
        folder: media.folder,
        provider: media.provider,
        resources: media.resources,
        streamType: StreamType.LIV,
    }

    if ('archive_link' in media) {
        stream.archiveLink = media.archive_link
        stream.streamType = media.stream_type
    }

    const data = await getStreamInformation(
        stream,
        isTouchScreen() && !uiStoreService.isLaptop()
    )

    if (data) {
        mediaRequestId.value = data.requestId || data.request_id
        mediaSources.value = data.sources

        if (data.idChannel) {
            idChannelPlaying.value = data.idChannel
        }

        if (data.iframe) {
            mediaType.value = 'iframe'
        } else if (data.audio) {
            mediaType.value = 'audio'
        } else if (data.script) {
            mediaType.value = 'script'
            scriptInfo.value = data
        } else {
            mediaType.value = 'video'
        }
    } else {
        idChannelPlaying.value = null
    }
}

/**
 * Streams player actions
 *
 * e.g. interact with streams player
 */
export function useStreamsPlayer() {
    return {
        isVisible,
        isModalVisible,
        isStandaloneVisible,
        isPlayButtonVisible,
        mediaPlayingIdRace,
        mediaSources,
        mediaType,
        nextStreamsStartTimeLeft,
        scriptInfo,
        isPlayerSticky,
        isPiPOpen,
        onOpen,
        onClose,
        onOpenModal,
        onCloseModal,
        onOpenStandalone,
        onCloseStandalone,
        handleStickyPlayer,
        handlePlayerPopup,
        onEnterPictureInPicture,
        onLeavePictureInPicture,
    }
}

/**
 * Streams channels with races
 *
 * i.e. use streams data and fetch races event
 * e.g. in "StreamsList" component
 */
export function useStreamsChannels() {
    return {
        isFetching: isFetchingChannels,
        idChannelFetching,
        idChannelPlaying,
        channels: computed(() => createStreamsListChannels(channels.value)),
        fetchChannelRaces,
        startPlayingChannel,
        startPlayingDefaultChannel,
    }
}
