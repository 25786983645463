import { createApp } from 'vue'

import { i18n } from '@obr-core/i18n/i18n'
import { store } from '@obr-core/store/store'
import { registerGlobalComponents } from '@obr-ui/helpers/components.helpers'
import { initialiseDirectives } from '@obr-ui/directives/directives'
import { ErrorService } from '@obr-core/services/ErrorService'
import { APP_MOUNT } from '@obr-core/config/app'
import { router } from '@/router/router'

import '@obr-ui-styles/styles.scss'

import App from '@obr-brand/App.vue'

let app = createApp(App)

app.use(i18n)
app.use(store)
app.use(router)
app = initialiseDirectives(app)
app = registerGlobalComponents(app)

// Initialize error service (Sentry)
ErrorService.getInstance().initialize({ app, router })

app.mount(APP_MOUNT)
