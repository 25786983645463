import { postMessageService } from '@obr-core/services/PostMessageService'
import { POST_MESSAGE_ID_NAVIGATION } from '@obr-core/config/post-messages'
import { getMatchedModules } from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'
import { userStoreService, i18nStoreService } from '@obr-core/services/store'

/**
 * Router hook after each routing event
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 */
export function onAfterEach(
    to: OBR.Router.Route /* , from: OBR.Router.Route */
) {
    postMessageService.send(POST_MESSAGE_ID_NAVIGATION, to.path)
}

/**
 * Global before guards are called in creation order, whenever a navigation is triggered.
 * Guards may be resolved asynchronously, and the navigation is considered pending before
 * all hooks have been resolved.
 *
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeEach(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    const loggedIn = userStoreService.isLoggedIn()

    // Auth Guard
    if (to.matched.some((record) => record.meta.auth === true) && !loggedIn) {
        userStoreService.authRequired()
        next(false)
        return
    }
    // Visible Route Guard
    if (to.matched.some((record) => record.meta.visible === false)) {
        next('/')
        return
    }

    // Get i18n modules required by all matching routes
    const i18nService = I18nService.getInstance()
    const i18nModulesRequired = i18nService.getRequiredModules(
        getMatchedModules(to.matched)
    )

    if (i18nModulesRequired.length > 0) {
        i18nStoreService
            .loadModules({ modules: i18nModulesRequired })
            .then(() => {
                next()
            })
    } else {
        next()
    }
}

/**
 * Similar to `router.beforeEach`, with the difference that resolve guards will be called
 * right before the navigation is confirmed, after all in-component guards and async route
 * components are resolved
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeResolve(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    next()
}
