<script lang="ts">
export default {
    name: 'ObrCheckbox',
}
</script>
<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import {
    PROVIDER_CHECK_LIST,
    PROVIDER_UPDATE_CHECK_LIST,
} from 'components/Checkbox/config'

import ObrSvgIcon from 'components/SvgIcon/SvgIcon.vue'

export interface Props {
    custom?: boolean
    checked?: boolean
    disabled?: boolean
    label?: string
    name?: string
    modelValue?: boolean
    stopPropagation?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    custom: false,
    checked: false,
    disabled: false,
    label: undefined,
    name: undefined,
    modelValue: undefined,
    stopPropagation: false,
})

const emits = defineEmits(['update:modelValue', 'change'])

const checkList = inject<OBR.Vue.Ref<string[] | undefined>>(
    PROVIDER_CHECK_LIST,
    ref(undefined)
)
const updateCheckList = inject<
    OBR.UI.Components.Checkbox.UpdateCheckList | undefined
>(PROVIDER_UPDATE_CHECK_LIST, undefined)

const model = computed({
    get(): boolean {
        return props.modelValue !== undefined
            ? !!props.modelValue
            : selfModel.value
            ? selfModel.value
            : props.checked
    },
    set(value: boolean) {
        emits('update:modelValue', value)
        selfModel.value = value
    },
})

const isChecked = computed(() => {
    return props.label && checkList.value
        ? checkList.value.includes(props.label)
        : model.value
})

const handleChange = (event: Event) => {
    if (props.label && updateCheckList) updateCheckList(props.label)
    emits('change', input.value?.checked, event)
}

const onLabelClick = (event: MouseEvent | TouchEvent) => {
    props.stopPropagation && event.stopPropagation()
}

const selfModel = ref<boolean>(false)
const focus = ref<boolean>(false)
const input = ref<HTMLInputElement | undefined>(undefined)
</script>
<template>
    <label
        role="checkbox"
        :aria-checked="isChecked"
        :aria-disabled="disabled"
        class="flex cursor-pointer items-center"
        :class="disabled && 'cursor-not-allowed'"
        @click="onLabelClick"
    >
        <div
            v-if="!custom"
            :class="[
                disabled
                    ? 'border-[--ipt-inactive-stroke] bg-[--ipt-inactive-background]'
                    : 'bg border-[--ipt-stroke] bg-[--ipt-background]',
            ]"
            class="w-23px h-23px min-w-23px relative overflow-hidden rounded-lg border"
        >
            <obr-svg-icon
                v-if="isChecked"
                class="absolute left-2 top-0 h-7 w-7 fill-current"
                :class="
                    disabled
                        ? 'text-[--ipt-inactive-text]'
                        : 'text-[--ipt-text]'
                "
                name="checkmark"
            ></obr-svg-icon>
        </div>
        <input
            v-model="model"
            :disabled="disabled"
            :value="label"
            :name="name"
            aria-hidden="true"
            type="checkbox"
            hidden
            @change="handleChange"
            @focus="focus = true"
            @blur="focus = false"
        />

        <div
            v-if="$slots.default || label"
            :class="[
                !custom && disabled ? 'text-[--txt-2]' : 'text-[--txt-main] ',
                !custom && 'relative ml-4 text-lg font-semibold',
            ]"
        >
            <slot></slot>
            <template v-if="!$slots.default">{{ label }}</template>
        </div>
    </label>
</template>
